body, html { 
}
p {
    font-weight: normal;
}
.x-bold-txt {
    font-weight: 900;
}
.page-wrapper-a {
    font-family: 'Poppins', sans-serif;
    background-color: #000000;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
}

/* TOP NAVIGATION */
.header-nav-a {
  position: absolute;
  z-index: 1000;
  top:0px;
  width: 100%;
}
.menu-icon-container-a {
    position: absolute;
    left: 20px;
    top:15px;
    padding: 0;
    margin:0;
    
}
.menu-icon-a {

    width: 150px;
    height: 50px;
    
}
.logo-a {
    position: absolute;
    left: 65px;
    width:220px;
    padding-top:25px;
    margin-left:20px;
}
.top-nav-a {
    padding-top:20px;
    padding-right: 30px;
    text-align: right;
    cursor: pointer;
}
/* END TOP NAVIGATION */

.barrows-border-color {
    border-color: #ff2363;
}
.barrows:focus {
    outline: none !important;
}
.barrows {
    background-color: #ff2363
}
.walmart {
    background-color: #05a2fd;
    -webkit-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
}
.walmart:focus {
    outline: none !important; 
}
.walmart-border-color {
    border-color: #05a2fd;
}
.tesco {
    background-color: #e62432;
    -webkit-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
}
.tesco:focus {
    outline: none !important; 
}
.tesco-border-color {
    border-color: #e62432;
}
.shoprite {
    background-color: #f5b827;
    -webkit-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
}
.shoprite-border-color {
    border-color: #f5b827;
}
.shoprite:focus {
    outline: none !important;
}
.barrows-text {
    color: #ff2363
}
.walmart-text {
    color: #05a2fd;
    -webkit-transition: color 1000ms linear;
    -ms-transition: color 1000ms linear;
    transition: color 1000ms linear;
}
.tesco-text {
    color: #e62432;
    -webkit-transition: color 1000ms linear;
    -ms-transition: color 1000ms linear;
    transition: color 1000ms linear;
}
.shoprite-text {
    color: #f5b827;
    -webkit-transition: color 1000ms linear;
    -ms-transition: color 1000ms linear;
    transition: color 1000ms linear;
}
.barrows-dot, .walmart-dot, .shoprite-dot, .tesco-dot {
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    -webkit-transition: background 1000ms linear;
    -moz-transition: background 1000ms linear;
    -o-transition: background 1000ms linear;
    -ms-transition: background 1000ms linear;
    transition: background 1000ms linear;
}
.barrows-dot {

    background-image: url("https://assets.barrowsconnectedstore.com/home/images/barrows-dot.png");
}
.walmart-dot {

    background-image: url("https://assets.barrowsconnectedstore.com/home/images/walmart-dot.png");
}
.tesco-dot {
    background-image: url("https://assets.barrowsconnectedstore.com/home/images/tesco-dot.png");
}
.shoprite-dot {
    background-image: url("https://assets.barrowsconnectedstore.com/home/images/shoprite-dot.png");
}
.header-grid-a {
    display: grid;    
}
.video-container-a {
    grid-column: 1/8;
}
.video-overlay-a {
    grid-column: 1/8;
     width: 100%;
     height: 100%;
     content: '';
     background: radial-gradient(circle at 50% 2%, rgba(0, 0, 0, 0.8) 0%, rgb(0, 0, 0, .9) 40%, rgba(0, 0, 0, .9) 20%);
}
.hmg-blur-background {
    background: rgb(98,98,98);
    background: linear-gradient(180deg, rgba(98,98,98,1) 0%, rgba(92,92,92,1) 43%, rgba(0,0,0,1) 100%);
}



.contact-form-container {
    display: block;
}
.gray-to-black-full-span {
    display: block;
    height: 400px;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(51,51,51,1) 100%);
}
.contact-us {
    margin-top: 50px;
}
.contact-form {
    padding: 20px;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
}
.form-input-background {
    background-color: #727272;
}
.form-required-message {
    font-weight: normal;
    font-size: .75em;
}
.form-error-message {
    color: #e62432;
    font-style: italic;
    font-size: .9em;
}
.form-error-highlight:focus {
    border: 3px solid #e62432;

}

.partner-metrics {
    grid-column: 1/5;
    border: 1px solid #656565;
    border-right: 0px;
    border-left: 0px;
    height: 130px;
}
.metric-top-number {
    font-size: 75px;
    font-weight: 900;
    text-align: center;
    margin-bottom: -60px;
}
.metric-bottom-text {
    text-align: center;
    font-size: 14px;
    line-height: 90px;
}

.brand-section {
    display: grid;
    grid-column: 1/5;
    margin-top: 150px; 
}
.logos-section {
    grid-column:1/5;
    margin-top: 100px;
    margin-bottom: 100px;
}
.logo-v2 {
    max-width: 120px;
}

@media screen and (min-width: 0px) and (max-width:350px) {
    .header-login {
        display: none;
    }
}
@media screen and (max-width: 780px) {
    .metric-top-number {
        font-size: 52px;
        margin-bottom: -52px;
    }
    .partner-metrics {
        height: 95px;
    }
}

@media screen and (max-width: 500px) {
    .metric-bottom-text {
        font-size: 8px
    }
}


@media only screen and (min-width: 1024px) and (max-width: 1248px) {
    .metric-top-number {
        font-size: 86px;
        margin-bottom: -60px;
    }
    .metric-bottom-text {
        text-align: center;
        font-size: 16px;
        line-height: 90px;
    }
}


@media only screen and (min-width: 1248px) and (max-width: 1472px) {
  
    .partner-metrics {
        height: 150px;
    }
    .metric-top-number {
        font-size: 86px;
        margin-bottom: -60px;
    }
    .metric-bottom-text {
        text-align: center;
        font-size: 16px;
        line-height: 90px;
    }

}
@media only screen and (min-width: 1472px) and (max-width: 1696px) {
  
    .brand-section {
        margin-top: 150px;
    }
    .partner-metrics {
        height: 173px;
    }
    .metric-top-number {
        font-size: 99px;
        margin-bottom: -60px;
    }
    .metric-bottom-text {
        text-align: center;
        font-size: 18px;
        line-height: 90px;
    }
    
   
}
@media only screen and (min-width: 1696px) and (max-width: 1920px) {
    
    .brand-section {
        margin-top: 150px;
    }
    .partner-metrics {
        height: 199px;
    }
    .metric-top-number {
        font-size: 114px;
        margin-bottom: -60px;
    }
    .metric-bottom-text {
        text-align: center;
        font-size: 21px;
        line-height: 90px;
    }
    

}
@media only screen and (min-width: 1920px)  {
  
    .brand-section {
        margin-top: 150px;
    }
    .partner-metrics {
        height: 239px;
    }
    .metric-top-number {
        font-size: 137px;
        margin-bottom: -60px;
    }
    .metric-bottom-text {
        text-align: center;
        font-size: 25px;
        line-height: 90px;
    }
   
}



.headline-pill-v2 {
    width: 80px;
    height: 10px;
    border-radius: 500px;
}

.headline-hr {
    top:70px;
}

.sub-section-headline-v2 {
    font-weight: bold;
}

.callout-container {
    display: flex;
    flex-direction: row;
    position: absolute;
    z-index: 1000;
    width: 800px;
    top:350px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
}
@media (min-width: 770px)  {
    .headline-pill-v2 {
        width: 120px;
    }
    .headline-hr {
        top:81px;
    }
}

