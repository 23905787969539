body, html {
   
}
.kroger-page-wrapper {
    background-color: #F9FAFB;
}
p {
    font-weight: normal;
}
.x-bold-txt {
    font-weight: 900;
}

/* TOP NAVIGATION */
.header-nav {
  position: absolute;
  z-index: 1000;
  top:0px;
  width: 100%;
}
.menu-icon-container {
    position: absolute;
    left: 20px;
    top:15px;
    padding: 0;
    margin:0;
    
}
.menu-icon {

    width: 150px;
    height: 50px;
    
}
.logo {
    position: absolute;
    left: 65px;
    width:220px;
    padding-top:25px;
    margin-left:20px;
}
.top-nav {
    padding-top:20px;
    padding-right: 30px;
    text-align: right;
    cursor: pointer;
}
/* END TOP NAVIGATION */

.responsive-image {
    width: 100%;
    max-width: 350px;
    height: auto;
    margin-left: 10px;
    margin-top: 5px;
}
.responsive-image-grid {
    width: 100%;
    height: auto;
}
@media screen and (max-width: 780px) {
    
}
.cs-poc-logo {
    float: right;
}

.welcome-headline {
    line-height: 1.15em;
}

#contactForm input[type='text'], input[type='email'], textarea {
    border-color: #DBEAFE;
}
.modal-body p {
    padding:5px;
    padding-bottom: 10px;
    width: 100%;
    line-height: 2em;
}

.modal-body span {
    font-weight: 1000;
}

.modal-small-title {
    font-size: 1.15em;
    font-weight: bold;
}