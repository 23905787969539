/* TOASTER */

.toaster {
    left: 50%;
    top: -100px;
    position: fixed;
    transform: translate(-50%, 0px);
    z-index: 9999;
}
.toasty-toaster {
  left: 50%;
  position: fixed;
  transform: translate(-25%, 0px);
  z-index: 9999;
}
@keyframes fadeIn {
    0% {  }
    100% { opacity: 1; top: 15px;}
  }
.toaster-in {
    animation: fadeIn 1s;
    animation-fill-mode: forwards;
}
@keyframes fadeOut {
    0% { top:15px; }
    100% {  top:-100px; }
  }
.toaster-out {
    animation: fadeOut 1s; 
}