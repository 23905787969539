.side-bar-nav {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: -300px;
    transition: 0.3s;
}
.nav-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 250px;
    height: 100vh;
}
.close-button {
    position: absolute;
    right: 10px;
    top:5px;
}
.menu-items {
    padding: 60px 10px 30px 10px;
}
.menu-item {
    width: 200px;
    height: 40px;
}
.large-icon {
    transform: scale(1.3);
}
.slideIn {
    animation-fill-mode: forwards;
    transition: 1s;
    left:0px;
}
.slideOut {
    animation-fill-mode: forwards;
    transition: 1s;
    left:-300px;
}